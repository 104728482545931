import React from "react";
import WebHeader from "../../HeaderFooterSidebar/WebHeaderFooter/WebHeader";
import WebFooter from "../../HeaderFooterSidebar/WebHeaderFooter/WebFooter";
import TitleBg from "../../assets/img/web/title-bg.png";
import "./WebHome.css";
import "./PrivacyPolicy.css";

function TermsService() {
  return (
    <>
      <div className="webWraper">
        <WebHeader />
        <div className="pageWraper privacyPolicyarea">
          <div className="privacyPolicy">
            <div className="container">
              <div className="privacyPolicyTitle">
                <img src={TitleBg} className="TitleBg" alt="TitleBg" />
                <h1>Terms of Service</h1>
                <p>Last updated: Jan 2025</p>
              </div>
              <div className="privacyPolicyContent">
                This website-hosted interface (the "Interface") is provided by
                Emmet Finance Inc. ("Emmet Finance", "we", "our", or "us"). The
                Interface provides users with an informational gateway regarding
                our decentralized protocol on various blockchain networks,
                allowing users to bridge, stake, and pool specific digital
                assets (the "Emmet Protocol" or the "Protocol"). This Interface
                is one, but not the exclusive, method of preparing data inputs
                for purposes of interacting with the Protocol. These Terms of
                Service ("Terms") explain the terms and conditions by which you
                may access and use the Interface. By accessing or using the
                Interface, you acknowledge that you have read, understood, and
                agree to be bound by these Terms in their entirety. If you do
                not agree with these Terms, you are not authorized to access or
                use the Interface.
                <br />
                <br />
                PLEASE NOTE: THE "DISPUTE RESOLUTION" SECTION OF THESE TERMS
                CONTAINS AN ARBITRATION CLAUSE THAT REQUIRES DISPUTES TO BE
                ARBITRATED ON AN INDIVIDUAL BASIS AND PROHIBITS CLASS ACTION
                CLAIMS. IT AFFECTS HOW DISPUTES BETWEEN YOU AND EMMET FINANCE
                ARE RESOLVED. BY ACCEPTING THESE TERMS, YOU AGREE TO BE BOUND BY
                THIS ARBITRATION PROVISION. PLEASE READ IT CAREFULLY.
                <br />
                <br />
                <b>Modification of these Terms</b>
                Emmet Finance reserves the right, at our sole discretion, to
                modify these Terms from time to time. If we make any
                modifications, we will notify you by updating the date at the
                top of the Terms. You can always find the most current version
                of these Terms at our official website. Modifications will
                become effective immediately upon being posted on the Interface,
                and your continued use of the Interface will be taken as
                acceptance of the modified Terms. If you do not agree with the
                modified Terms, you should stop using the Interface immediately.
                <br />
                <br />
                <b>Eligibility</b>
                You must be of legal age in your jurisdiction to form a binding
                contract to accept these Terms, and you must not be barred from
                doing so under any applicable laws. You accept and agree that
                you are legally able to accept these Terms, and affirm that you
                are of legal age in your jurisdiction, or that you have obtained
                parental or guardian consent if necessary. You further represent
                that you have the full right, power, and authority to comply
                with these Terms on behalf of yourself and any company or legal
                entity for which you may be using the Interface.
                <br />
                <br />
                <b>Compliance and Prohibitions</b>
                You agree that you will not violate any laws in connection with
                your use of the Interface. This includes any local, state,
                federal, and international laws that may apply to you. You may
                not use our Interface to engage in any illegal activities or to
                conduct activities that could give rise to civil or criminal
                liability.
                <br />
                <br />
                <b>Liability and Indemnification</b>
                You agree to hold harmless and indemnify Emmet Finance and its
                affiliates, officers, agents, and employees from and against any
                claims, disputes, demands, liabilities, damages, losses, and
                costs and expenses, including, without limitation, reasonable
                legal and accounting fees arising out of or in any way connected
                with your access to or use of the Interface or your violation of
                these Terms.
                <br />
                <br />
                Please note, this is a simplified and generalized version of a
                Terms of Service agreement. You should consult with a lawyer to
                create a tailored terms of service agreement for your business.
                <br />
                <br />
                <b>Privacy</b>
                Your privacy is important to us. Please review our Privacy
                Policy for information about the data we may collect and how we
                use and share it. Your use of the Interface indicates that you
                agree with the data practices stated in our Privacy Policy.
                <br />
                <br />
                <b>Prohibited Activity</b>
                You agree not to engage in, and to not use the Interface to
                facilitate, any conduct that violates any applicable law or
                regulation, including, without limitation, any copyright,
                trademark, or other intellectual property laws, or other rights
                of any person or entity. You agree not to use the Interface for
                any illegal or unauthorized purpose.
                <br />
                <br />
                <b>Not Registered with FinCEN or any agency</b>
                Emmet Finance is not registered with the Financial Crimes
                Enforcement Network (FinCEN) or any other governmental or
                regulatory body. This Interface does not facilitate the buying
                or selling of securities, the exchange of digital assets, or any
                other financial transactions.
                <br />
                <br />
                <b>
                  Non-Solicitation; No Investment Advice; No Fiduciary Duties
                </b>
                The content and information provided through the Interface are
                for informational purposes only and do not constitute
                solicitation, investment advice, or endorsement by Emmet
                Finance. All decisions to act upon any of the content provided
                through the Interface are your sole responsibility. We have no
                fiduciary duty to you and are not your financial advisor or
                fiduciary.
                <br />
                <br />
                <b>No Warranties</b>
                The Interface and all materials, software, and content available
                through it are provided "as is" and on an "as available" basis,
                without warranty or condition of any kind, either express or
                implied.
                <br />
                <br />
                <b>Non-Custodial and No Fiduciary Duties</b>
                Emmet Finance is a non-custodial service. We do not store, send,
                or receive digital assets. Transactions are validated and
                confirmed solely by the relevant blockchain network. No private
                keys or digital assets are transferred to or held by Emmet
                Finance.
                <br />
                <br />
                <b>Compliance Obligations</b>
                You are responsible for complying with all applicable laws and
                regulations related to your use of the Interface and any
                transactions that you make through it. You agree to indemnify
                and hold us harmless if you do not comply with all applicable
                laws and regulations.
                <br />
                <br />
                Again, this is a generalized version of a Terms of Service
                agreement. It is essential to consult with a legal professional
                when creating a tailored terms of service agreement for your
                business.
                <br />
                <br />
                <b>Assumption of Risk</b>
                You acknowledge and accept that there are risks associated with
                utilizing an Internet-based system including, but not limited
                to, the risk of failure of hardware, software, and Internet
                connections. You acknowledge that Emmet Finance shall not be
                responsible for any communication failures, disruptions, errors,
                distortions, or delays you may experience when using the
                Interface, however caused.
                <br />
                <br />
                <b>Third-Party Resources and Promotions</b>
                The Interface may contain links to third-party websites or
                services that are not owned or controlled by Emmet Finance. We
                do not endorse or assume any responsibility for any such
                third-party sites, information, materials, products, or
                services.
                <br />
                <br />
                <b>Release of Claims</b>
                To the maximum extent permitted by applicable law, you release
                Emmet Finance and its officers, directors, employees, and agents
                from any and all claims, actions, damages, losses, liabilities,
                costs, and expenses of any kind whatsoever that you may have
                against them arising out of or in any way relating to your use
                of the Interface.
                <br />
                <br />
                <b>Indemnity</b>
                You agree to defend, indemnify, and hold harmless Emmet Finance
                and its officers, directors, employees, and agents from any
                claims, actions, damages, losses, liabilities, costs, and
                expenses, including, without limitation, reasonable legal and
                accounting fees, arising out of or in any way connected with
                your access to or use of the Interface or your violation of
                these Terms.
                <br />
                <br />
                <b>Limitation of Liability</b>
                In no event shall Emmet Finance or its officers, directors,
                employees, or agents be liable for any special, incidental,
                indirect, or consequential damages whatsoever arising out of or
                in any way related to your use of or inability to use the
                Interface.
                <br />
                <br />
                <b>Dispute Resolution</b>
                You agree to resolve any disputes arising out of or related to
                these Terms or the Interface through final and binding
                arbitration, except as set forth under exceptions to agreement
                to arbitrate below.
                <br />
                <br />
                Class Action and Jury Trial WaiverYou may only resolve disputes
                with us on an individual basis and may not bring a claim as a
                plaintiff or a class member in a class, consolidated, or
                representative action. <br /> <br />
                <b>Governing Law</b>
                These Terms and any dispute or claim arising out of or in
                connection with their subject matter or formation shall be
                governed by and construed in accordance with the law of [insert
                your jurisdiction].
                <br />
                <br />
                <b>Entire Agreement</b>
                These Terms, together with the Privacy Policy, constitute the
                entire agreement between you and Emmet Finance regarding the use
                of the Interface, superseding any prior agreements between you
                and Emmet Finance relating to your use of the Interface.
                <br />
                <br />
                Remember to consult with a legal professional when creating your
                terms of service agreement. This example does not constitute
                legal advice and may not be appropriate for your specific
                circumstances.
              </div>
            </div>
          </div>
        </div>
        <WebFooter />
      </div>
    </>
  );
}

export default TermsService;
